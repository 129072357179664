<template>
  <!--暂无数据-->
  <div class="">
    <div class="text-center" style="margin-top: 15vh">
      <img
          style="display: block;width:auto;margin: 0 auto;"
          src="@/assets/img/no_data.png"
          alt="img"
      />
      <div style="font-size:16px;color: #999;margin: 28px 0;">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    // 标题
    title: {
      type: String,
      default: '暂无数据',
    },
  },
};
</script>

<style scoped>

</style>
